<template>
  <div class="full-card has-table">
    <div class="handle-area">
      <a-button type="primary" icon="plus" @click="openCreate('create')" v-has-permission="['addUser']">新增用户</a-button>
      <div class="filter margin-l20" @click="filterShow = true"></div>
    </div>
    <a-card>
      <a-table
          :columns="columns"
          :data-source="list"
          :loading="tableLoading"
          :pagination="pagination"
          rowKey="id"
          bordered
          @change="pageChange"
          :scroll="{ y: 'calc(100% - 55px)'}"
      >
        <template slot="userType" slot-scope="text">
          <span v-if="text === 1">员工</span>
          <span v-if="text === 2">客户</span>
        </template>
        <template slot="status" slot-scope="text">
          <span v-if="text === 1">可用</span>
          <span v-if="text === 0">停用</span>
        </template>
        <template slot="id" slot-scope="text, record">
          <img src="@/assets/image/common/edit.png" alt="" @click="openCreate('edit', record)" v-has-permission="['editUser']">
          <img src="@/assets/image/common/delete.png" alt="" class="margin-l20" @click="delItem(record)" v-has-permission="['deleteUser']">
        </template>
      </a-table>
    </a-card>
    <a-drawer
        title="过滤器"
        placement="right"
        :visible="filterShow"
        @close="filterShow = false"
        width="500px"
        class="drawer"
    >
      <div class="drawer-cot">
        <a-form-model labelAlign="right" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" :model="filterForm" class="drawer-form">
          <a-form-model-item :wrapperCol="{ span: 24 }">
            <a-input-search placeholder="登录名/用户姓名" v-model="filterForm.name" @search="onFilter" />
          </a-form-model-item>
          <a-row style="margin-top: 20px">
            <a-form-model-item  label="用户类型">
              <a-select v-model="filterForm.userType" placeholder="请选择用户类型">
                <a-select-option :value="1">员工</a-select-option>
                <a-select-option :value="2">客户</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="用户角色">
              <a-select v-model="filterForm.roleId" placeholder="请选择用户角色">
                <a-select-option :value="item.id" v-for="(item, index) in roleList" :key="item.id">{{ item.roleName }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="账号状态">
              <a-select v-model="filterForm.status" placeholder="请选择账号状态">
                <a-select-option :value="1">可用</a-select-option>
                <a-select-option :value="0">停用</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-row>
        </a-form-model>
        <div class="drawer-handle">
          <a-button type="primary" icon="search" @click="onFilter">筛选</a-button>
          <a-button style="margin-left: 10px;" icon="reload" @click="onReset">重置</a-button>
        </div>
      </div>
    </a-drawer>
    <a-drawer
        :title="`${ form.id ? '编辑' : '新增'}用户` "
        placement="right"
        :visible="createShow"
        @close="closeCreate"
        width="500px"
        class="drawer"
    >
      <div class="drawer-cot">
        <a-form-model
            ref="form"
            labelAlign="right"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }"
            :model="form"
            :rules="rules"
            class="drawer-form"
        >
          <a-row>
            <a-form-model-item label="登录名" prop="account">
              <a-input placeholder="请输入登录名" v-model="form.account" />
            </a-form-model-item>
            <a-form-model-item  label="用户类型" prop="userType">
              <a-select v-model="form.userType" placeholder="请选择用户类型" @change="userTypeChange">
                <a-select-option :value="1">员工</a-select-option>
                <a-select-option :value="2">客户</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="员工/客户姓名" prop="relatedId">
              <a-button block @click="openSelect">
                <div class="input-box" v-if="form.relatedId">{{ form.name }}</div>
                <div class="input-box placeholder" v-else>请选择员工/客户</div>
              </a-button>
            </a-form-model-item>
            <a-form-model-item label="用户角色" prop="roleId">
              <a-select v-model="form.roleId" placeholder="请选择用户角色">
                <a-select-option :value="item.id" v-for="(item, index) in roleList" :key="item.id">{{ item.roleName }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="账号状态" prop="status">
              <a-select v-model="form.status" placeholder="请选择账号状态">
                <a-select-option :value="1">可用</a-select-option>
                <a-select-option :value="0">停用</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-row>
        </a-form-model>
        <div class="drawer-handle">
          <a-button type="primary" icon="check" @click="onSubmit">确定</a-button>
          <a-button icon="close" class="margin-l20" @click="closeCreate">取消</a-button>
        </div>
      </div>

    </a-drawer>
    <SelectStaff
        :visible="staffShow"
        :type="form.userType"
        @submit="selectChange"
        @close="staffShow = false"
        v-if="staffShow"
    ></SelectStaff>
  </div>
</template>

<script>
import { getUserList, createUser, deleteUser } from '@/api/system/user'
import { getRoleList } from '@/api/system/role'
import { mapState } from 'vuex'
import SelectStaff from '@/components/select/selectStaff'

export default {
  name: 'user',
  components: {
    SelectStaff
  },
  data () {
    return {
      columns: [
        {
          title: '登录名',
          dataIndex: 'account'
        },
        {
          title: '用户类型',
          dataIndex: 'userType',
          scopedSlots: { customRender: 'userType' }
        },
        {
          title: '员工/客户姓名',
          dataIndex: 'name'
        },
        {
          title: '用户角色',
          dataIndex: 'roleName'
        },
        {
          title: '微信昵称',
          dataIndex: 'wechatName'
        },
        {
          title: '注册时间',
          dataIndex: 'createTime'
        },
        {
          title: '最近登录时间',
          dataIndex: 'loginTime'
        },
        {
          title: '最近登录IP',
          dataIndex: 'loginIp'
        },
        {
          title: '账号状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '最近修改时间',
          dataIndex: 'updateTime'
        },
        {
          title: '修改人',
          dataIndex: 'modifier'
        },
        {
          title: '操作',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' }
        }
      ],
      list: [],
      total: 0,
      createShow: false,
      filterShow: false,
      filterForm: {
        pageNum: 1,
        pageSize: 10
      },
      form: {},
      page: 1,
      roleList: [],
      tableLoading: true,
      pagination: {
        total: 0,
        pageSize: 10
      },
      staffShow: false,
      customerShow: false,
      rules: {
        account: [
          { required: true, message: '请输入登录名', trigger: 'blur' }
        ],
        userType: [
          { required: true, message: '请选择用户类型', trigger: 'change' }
        ],
        relatedId: [
          { required: true, message: '请选择员工/客户', trigger: 'change' }
        ],
        roleId: [
          { required: true, message: '请选择用户角色', trigger: 'change' }
        ]
      }
    }
  },
  mounted () {
    this.getList()
    this.getRoleList()
  },
  methods: {
    getList () {
      return new Promise( (resolve) => {
        this.tableLoading = true
        getUserList(this.filterForm).then(res => {
          this.list = res.data.list
          this.pagination.total = res.data.total
          this.tableLoading = false
          resolve(res.data)
        })
      })
    },
    pageChange (pages) {
      this.filterForm.pageNum = pages.current
      this.getList()
    },
    openCreate (type, item) {
      if (item) this.form = JSON.parse(JSON.stringify(item))
      this.createShow = true
    },
    closeCreate () {
      this.form = {}
      this.createShow = false
    },
    delItem (item) {
      this.$confirm({
        title: '提示',
        content: '确认删除这条数据？',
        centered: true,
        onOk: () => {
          deleteUser({
            ids: [item.id]
          }).then(() => {
            this.$message.success('删除成功！')
            this.getList()
          })
        }
      })
    },
    onSubmit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          createUser(this.form).then(() => {
            this.$message.success('操作成功！')
            this.getList()
            this.closeCreate()
          })
        }
      })
    },
    onFilter () {
      this.filterForm.pageNum = 1
      this.getList().then(() => {
        this.filterShow = false
      })
    },
    onReset () {
      this.filterForm = {
        pageNum: 1
      }
      this.getList()
    },
    getRoleList () {
      getRoleList({
        pageNum: 1,
        pageSize: 10000
      }).then(res => {
        this.roleList = res.data.list
      })
    },
    openSelect () {
      if (this.form.userType) {
        this.staffShow = true
      } else {
        this.$message.error('请选择用户类型！')
      }
    },
    selectChange (data) {
      this.form.relatedId = data.id
      this.form.name = data.staffName || data.customerName
      this.staffShow = false
    },
    userTypeChange () {
      this.form.relatedId = ''
      this.form.name = ''
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      authority: state => state.user.authority,
    }),
  },
}
</script>

<style scoped lang="scss">
 .input-box {
   //box-sizing: border-box;
   //position: relative;
   //width: 100%;
   //height: 32px;
   //line-height: 32px;
   //padding: 0 11px;
   //color: rgba(0, 0, 0, 0.65);
   //font-size: 14px;
   //background-color: #fff;
   //border: 1px solid #d9d9d9;
   //border-radius: 4px;
   //transition: all 0.3s;
   text-align: left;
   &.placeholder {
     color: #bdbdbd;
   }
 }
</style>
