import request from '@/utils/request'

export function getUserList(params) {
    return request({
        url: '/user/page',
        method: 'GET',
        params,
    })
}

export function createUser(data) {
    return request({
        url: data.id ? `/user/update/${data.id}` : `/user/create`,
        method: 'POST',
        data,
    })
}

export function deleteUser(data) {
    return request({
        url: '/user/delete',
        method: 'POST',
        data,
    })
}









