import request from '@/utils/request'

export function getRoleList(params) {
    return request({
        url: '/role/page',
        method: 'GET',
        params,
    })
}

export function createRole (data) {
    return request({
        url: data.id ? `/role/update/${data.id}` : `/role/create`,
        method: 'POST',
        data
    })
}

export function deleteRole(data) {
    return request({
        url: '/role/delete',
        method: 'POST',
        data,
    })
}

export function getRoleTree(data) {
    return request({
        url: `/role/getRoleAuths?roleId=${data.roleId}&authType=1`,
        method: 'POST',
        data,
    })
}

export function setRoleAuths(data) {
    return request({
        url: `/role/setRoleAuths`,
        method: 'POST',
        data,
    })
}









